<template>
  <div
    class="empty d-flex flex-column align-items-center justify-content-center h-100"
  >
    <i :class="`icon-${icon}`"></i>
    <p
      class="text-20 font-weight-bold mt-1 mb-0 text-center text-truncate"
      style="width: 80%"
    >
      {{ text }}
    </p>
    <p v-if="subText" class="m-0 mt-1">{{ subText }}</p>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: 'Kifu',
    },
    text: {
      type: String,
      default() {
        return this.$t('目前沒有內容');
      },
    },
    subText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.empty {
  color: $font-grayscale-3;
  i {
    color: rgba($font-grayscale-3, 0.5);
    font-size: 150px;
  }
}
</style>
