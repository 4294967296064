<template>
  <div class="no-lesson d-flex flex-column">
    <BaseHeader
      :title="title"
      :is-title-bold="$device.isMobile ? false : true"
      :mode="$device.isMobile ? 'secondary' : 'primary'"
      :back-on-click-left="false"
      :left-icon="$isJungo ? '' : 'Menu'"
      @on-click-left="onClickSidebar"
    ></BaseHeader>
    <div
      class="d-flex flex-column align-items-center justify-content-center h-100"
    >
      <EmptyPlaceholder
        :icon="icon"
        :text="text"
        :sub-text="sunText"
        class="empty"
      />
      <b-button variant="primary" size="md" class="mt-3" @click="onClick2Web">
        {{ $t('前往官網了解') }}
      </b-button>
    </div>
  </div>
</template>

<script>
import BaseHeader from '@/components/Base/BaseHeader';
import EmptyPlaceholder from '@/components/Base/EmptyPlaceholder.vue';
import {Browser} from '@capacitor/browser';
import BackEvent from '@/lib/base/backEvent.js';
import {manifest} from '@/constant/env.js';

export default {
  components: {
    BaseHeader,
    EmptyPlaceholder,
  },
  data() {
    return {
      types: {
        practiceGame: {
          icon: 'Kifu',
          text: this.$isJungo ? this.$t('AI對弈') : this.$t('下棋'),
        },
        gamelist: {
          icon: 'Kifu',
          text: this.$isJungo ? this.$t('AI對弈') : this.$t('下棋'),
        },
        practice: {icon: 'Battle', text: this.$t('下棋')},
        practiceTsume: {icon: 'Kifu2', text: this.$t('做題')},
        tsumelist: {icon: 'Kifu2', text: this.$t('做題')},
        pvp: {icon: 'Battle', text: this.$t('好友對戰')},
        verification: {icon: 'Verification', text: this.$t('檢定')},
      },
    };
  },
  computed: {
    title() {
      return this.types[this.type]?.text;
    },
    type() {
      return this.$route.params?.type;
    },
    icon() {
      return this.types[this.type]?.icon;
    },
    text() {
      return this.$t('當前課程不開放{type}', {
        type: this.types[this.type]?.text,
      });
    },
    sunText() {
      return this.$t('您可以切換其他課程或前往官網購買');
    },
  },
  created() {
    if (this.types[this.type] != null) {
      return;
    } else {
      this.$router.push({name: 'home'});
    }
  },
  methods: {
    onClick2Web() {
      const homePageUrl = manifest.homePageUrl;

      Browser.open({
        url: homePageUrl + '/courses',
        presentationStyle: 'popover',
      });
    },
    onClickSidebar() {
      this.$store.commit('env/setIsSidebarShow', true);
      BackEvent.addEvent(() => {
        this.$store.commit('env/setIsSidebarShow', false);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.empty {
  height: auto !important;
}
</style>
